import { useCallback, useEffect, useState } from "react";

/**
 * 
 * @param {Array} sounds 
 */
export function useSound(sounds){
    const [current, setCurrent] = useState(0);
    const [loadedSounds, setLoadedSounds] = useState([]);
    const increase = useCallback(()=>{
        let c = current+1;
        if (c >= loadedSounds.length) {
            c = 0;
        }
        setCurrent(c);
    }, [current, loadedSounds]);
    useEffect(()=>{
        if (current >= sounds.length) {
            setCurrent(0);
        }
        setLoadedSounds(sounds.map((s)=>new Audio(s)));
    }, [sounds]);

    return useCallback(()=>{
        loadedSounds[current].currentTime = 0;
        loadedSounds[current].play();
        increase();
    }, [loadedSounds, current, increase])
}