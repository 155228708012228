import { useEffect, useState } from "react";


export function useTime(callback = null){
    const [time, setTime] = useState("");
    const [date, setDate] = useState("");
    useEffect(()=>{
        const func = ()=>{
            const d = new Date();
            const time = `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`;
            const date = `${d.getFullYear()}.${(d.getMonth()+1).toString().padStart(2, '0')}.${d.getDate().toString().padStart(2, '0')}.`;
            setTime(time);
            setDate(date);
            if (callback !== null) {
                callback(d);
            }
        }
        const handle = setInterval(func, 1000);
        return ()=>{
            clearInterval(handle);
        }
    }, [callback]);
    return [time, date];

}

