import logo from './logo.svg';
import './App.css';
import { useTime } from './hooks/useTime';
import { useSound } from './hooks/useSound';

const s = [
  "tick.ogg",
  "tock.ogg",
];

function App() {
  const play = useSound(s);
  const [time, date] = useTime((_)=>play());
  return (
    <div className="center">
      <span className='time'>{time}</span>
      <span className='date'>{date}</span>
    </div>
  );
}

export default App;
